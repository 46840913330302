<div *ngIf="loading" class="preload">
  <div class="preload-image">
    <img src="assets/img/logo.png" alt="logo" width="350">
    <h4 style="text-align: center"><b>{{'WELLCOME_PRODUCT_CALCULATER' | translate}}</b></h4>
  </div>

</div>

<div class="grid justify-content-center">
  <div class="col-12 lg:col-12 xl:col-12 flex-row justify-content-between mobil-mode-hide">
    <i class="pi pi-refresh refresh-icon" (click)="reload()" style="float:left;height:40px !important;margin-top: 3px;"></i>
    <p-accordion>
      <p-accordionTab header="{{'DOOR_FEATURES' | translate}}">
        <div class="{{ doorType ?  'step-item-active flex': 'step-item'}} flex align-items-center" >
          <div class="icon-div vertical-align-middle">
            <i class="pi pi-check"></i>
          </div>
          <div class="title-div vertical-align-middle {{ doorType ? 'cursor-pointer': ''}}"
               (click)="doorType = undefined; isSelectedDoorType = false; showControl()">
            <b>{{'DOOR_TYPE' | translate}}</b><br>
            <span style="color: #FFFFFF" *ngIf="doorType !== undefined">
          {{doorTypeTitle | translate}}
      </span>
          </div>
        </div>
        <div class="{{ thickness ?  'step-item-active': 'step-item'}} flex align-items-center">
          <div class="icon-div vertical-align-middle">
            <i class="pi pi-check"></i>
          </div>
          <div class="title-div vertical-align-middle {{ thickness ? 'cursor-pointer': ''}}"
               (click)="width = undefined; height = undefined; thickness = undefined; isSelectedDimensions = false; showControl()">
            <b>{{'NET_PASSAGE_DIMENSIONS' | translate}}</b><br>
            <span style="color: #FFFFFF" *ngIf="width !== undefined && height !== undefined && thickness !== undefined ">
          {{width}}-{{height}}-{{thickness}} cm
        </span>
          </div>
        </div>
        <div class="{{ temperature ?  'step-item-active': 'step-item'}} flex align-items-center" *ngIf="doorType !== 3">
          <div class="icon-div vertical-align-middle">
            <i class="pi pi-check"></i>
          </div>
          <div class="title-div vertical-align-middle {{ temperature ? 'cursor-pointer': ''}}"
               (click)="temperature = undefined; isSelectedTemperature = false; showControl()">
            <b>{{'COLD_ROOM_TEMPERATURE' | translate}}</b><br>
            <span style="color: #FFFFFF" *ngIf="temperature">
          {{temperatureTitle | translate }}
        </span>
          </div>
        </div>
        <div class="{{ thresholdType ?  'step-item-active': 'step-item'}} flex align-items-center" *ngIf="doorType === 1">
          <div class="icon-div vertical-align-middle">
            <i class="pi pi-check"></i>
          </div>
          <div class="title-div vertical-align-middle {{ thresholdType ? 'cursor-pointer': ''}}"
               (click)="thresholdType = undefined; isSelectedThreshold = false; showControl()">
            <b>{{'PASSAGE_TYPE' | translate}}</b><br>
            <span style="color: #FFFFFF" *ngIf="thresholdType">
          {{thresholdTypeTitle | translate}}
        </span>
          </div>
        </div>
        <div class="{{ hingedDoorsCase ?  'step-item-active': 'step-item'}} flex align-items-center" *ngIf="doorType === 1">
          <div class="icon-divrtical-align-middle">
            <i class="pi pi-check"></i>
          </div>
          <div class="title-div vertical-align-middle {{ hingedDoorsCase ? 'cursor-pointer': ''}}"
               (click)="hingedDoorsCase = undefined; isSelectedHingedDoorsCases = false; showControl()">
            <b>{{'CASE_TYPE' | translate}}</b><br>
            <span style="color: #FFFFFF" *ngIf="hingedDoorsCase">
          {{hingedDoorsCaseTitle | translate}}
        </span>
          </div>
        </div>
        <div class="{{ slidingDoorsCase ?  'step-item-active': 'step-item'}} flex align-items-center"
             *ngIf="doorType === 2">
          <div class="icon-div vertical-align-middle">
            <i class="pi pi-check"></i>
          </div>
          <div class="title-div vertical-align-middle {{ slidingDoorsCase ? 'cursor-pointer': ''}}"
               (click)="slidingDoorsCase = undefined; isSelectedSlidingDoorsCases = false; showControl()">
            <b>{{'CASE_TYPE' | translate}}</b><br>
            <span style="color: #FFFFFF" *ngIf="slidingDoorsCase">
          {{slidingDoorsCaseTitle | translate}}
        </span>
          </div>
        </div>
        <div class="{{ doorLeaf ?  'step-item-active': 'step-item'}} flex align-items-center" *ngIf="doorType !== 3">
          <div class="icon-div vertical-align-middle">
            <i class="pi pi-check"></i>
          </div>
          <div class="title-div vertical-align-middle {{ doorLeaf ? 'cursor-pointer': ''}}"
               (click)="doorLeaf = undefined; isSelectedDoorLeaf = false; showControl()">
            <b>{{'LEAF_TYPE' | translate}}</b><br>
            <span style="color: #FFFFFF" *ngIf="temperature">
          {{doorLeafTitle | translate }}
        </span>
          </div>
        </div>
        <div class="{{ doorLeafFlap ?  'step-item-active': 'step-item'}} flex align-items-center" *ngIf="doorType === 3">
          <div class="icon-div vertical-align-middle">
            <i class="pi pi-check"></i>
          </div>
          <div class="title-div vertical-align-middle {{ doorLeafFlap ? 'cursor-pointer': ''}}"
               (click)="doorLeafFlap = undefined; isSelectedDoorLeafFlap = false; showControl()">
            <b>{{'WING_TYPE' | translate}}</b><br>
            <span style="color: #FFFFFF" *ngIf="doorLeafFlap">
          {{doorLeafFlapTitle | translate }}
        </span>
          </div>
        </div>
        <div class="{{ quantity ?  'step-item-active': 'step-item'}} flex align-items-center">
          <div class="icon-div vertical-align-middle">
            <i class="pi pi-check"></i>
          </div>
          <div class="title-div vertical-align-middle {{ quantity ? 'cursor-pointer': ''}}"
               (click)="quantity = undefined; isSelectedQuantity = false; showControl()">
            <b>{{'PRODUCE_QUANTITY' | translate}}</b><br>
            <span style="color: #FFFFFF" *ngIf="quantity">
          {{quantity}} {{ 'PIECE' | translate}}
        </span>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
  <div class="col-12 lg:col-12 xl:col-12 flex-row justify-content-between mobil-mode-show">
    <i class="pi pi-refresh refresh-icon" (click)="reload()" style="float:left;height:40px !important;"></i>
    <div class="{{ doorType ?  'step-item-active flex': 'step-item'}} flex align-items-center" >
      <div class="icon-div vertical-align-middle">
        <i class="pi pi-check"></i>
      </div>
      <div class="title-div vertical-align-middle {{ doorType ? 'cursor-pointer': ''}}"
           (click)="doorType = undefined; isSelectedDoorType = false; showControl()">
        <b>{{'DOOR_TYPE' | translate}}</b><br>
        <span style="color: #FFFFFF" *ngIf="doorType !== undefined">
          {{doorTypeTitle | translate}}
      </span>
      </div>
    </div>
    <div class="{{ thickness ?  'step-item-active': 'step-item'}} flex align-items-center">
      <div class="icon-div vertical-align-middle">
        <i class="pi pi-check"></i>
      </div>
      <div class="title-div vertical-align-middle {{ thickness ? 'cursor-pointer': ''}}"
           (click)="width = undefined; height = undefined; thickness = undefined; isSelectedDimensions = false; showControl()">
        <b>{{'NET_PASSAGE_DIMENSIONS' | translate}}</b><br>
        <span style="color: #FFFFFF" *ngIf="width !== undefined && height !== undefined && thickness !== undefined ">
          {{width}}-{{height}}-{{thickness}} cm
        </span>
      </div>
    </div>
    <div class="{{ temperature ?  'step-item-active': 'step-item'}} flex align-items-center" *ngIf="doorType !== 3">
      <div class="icon-div vertical-align-middle">
        <i class="pi pi-check"></i>
      </div>
      <div class="title-div vertical-align-middle {{ temperature ? 'cursor-pointer': ''}}"
           (click)="temperature = undefined; isSelectedTemperature = false; showControl()">
        <b>{{'COLD_ROOM_TEMPERATURE' | translate}}</b><br>
        <span style="color: #FFFFFF" *ngIf="temperature">
          {{temperatureTitle | translate }}
        </span>
      </div>
    </div>
    <div class="{{ thresholdType ?  'step-item-active': 'step-item'}} flex align-items-center" *ngIf="doorType === 1">
      <div class="icon-div vertical-align-middle">
        <i class="pi pi-check"></i>
      </div>
      <div class="title-div vertical-align-middle {{ thresholdType ? 'cursor-pointer': ''}}"
           (click)="thresholdType = undefined; isSelectedThreshold = false; showControl()">
        <b>{{'PASSAGE_TYPE' | translate}}</b><br>
        <span style="color: #FFFFFF" *ngIf="thresholdType">
          {{thresholdTypeTitle | translate}}
        </span>
      </div>
    </div>
    <div class="{{ hingedDoorsCase ?  'step-item-active': 'step-item'}} flex align-items-center" *ngIf="doorType === 1">
      <div class="icon-divrtical-align-middle">
        <i class="pi pi-check"></i>
      </div>
      <div class="title-div vertical-align-middle {{ hingedDoorsCase ? 'cursor-pointer': ''}}"
           (click)="hingedDoorsCase = undefined; isSelectedHingedDoorsCases = false; showControl()">
        <b>{{'CASE_TYPE' | translate}}</b><br>
        <span style="color: #FFFFFF" *ngIf="hingedDoorsCase">
          {{hingedDoorsCaseTitle | translate}}
        </span>
      </div>
    </div>
    <div class="{{ slidingDoorsCase ?  'step-item-active': 'step-item'}} flex align-items-center"
         *ngIf="doorType === 2">
      <div class="icon-div vertical-align-middle">
        <i class="pi pi-check"></i>
      </div>
      <div class="title-div vertical-align-middle {{ slidingDoorsCase ? 'cursor-pointer': ''}}"
           (click)="slidingDoorsCase = undefined; isSelectedSlidingDoorsCases = false; showControl()">
        <b>{{'CASE_TYPE' | translate}}</b><br>
        <span style="color: #FFFFFF" *ngIf="slidingDoorsCase">
          {{slidingDoorsCaseTitle | translate}}
        </span>
      </div>
    </div>
    <div class="{{ doorLeaf ?  'step-item-active': 'step-item'}} flex align-items-center" *ngIf="doorType !== 3">
      <div class="icon-div vertical-align-middle">
        <i class="pi pi-check"></i>
      </div>
      <div class="title-div vertical-align-middle {{ doorLeaf ? 'cursor-pointer': ''}}"
           (click)="doorLeaf = undefined; isSelectedDoorLeaf = false; showControl()">
        <b>{{'LEAF_TYPE' | translate}}</b><br>
        <span style="color: #FFFFFF" *ngIf="temperature">
          {{doorLeafTitle | translate }}
        </span>
      </div>
    </div>
    <div class="{{ doorLeafFlap ?  'step-item-active': 'step-item'}} flex align-items-center" *ngIf="doorType === 3">
      <div class="icon-div vertical-align-middle">
        <i class="pi pi-check"></i>
      </div>
      <div class="title-div vertical-align-middle {{ doorLeafFlap ? 'cursor-pointer': ''}}"
           (click)="doorLeafFlap = undefined; isSelectedDoorLeafFlap = false; showControl()">
        <b>{{'WING_TYPE' | translate}}</b><br>
        <span style="color: #FFFFFF" *ngIf="doorLeafFlap">
          {{doorLeafFlapTitle | translate }}
        </span>
      </div>
    </div>
    <div class="{{ quantity ?  'step-item-active': 'step-item'}} flex align-items-center">
      <div class="icon-div vertical-align-middle">
        <i class="pi pi-check"></i>
      </div>
      <div class="title-div vertical-align-middle {{ quantity ? 'cursor-pointer': ''}}"
           (click)="quantity = undefined; isSelectedQuantity = false; showControl()">
        <b>{{'PRODUCE_QUANTITY' | translate}}</b><br>
        <span style="color: #FFFFFF" *ngIf="quantity">
          {{quantity}} {{ 'PIECE' | translate}}
        </span>
      </div>
    </div>
  </div>
</div>

<!--- Door Type-->
<div class="grid justify-content-center mt-8" *ngIf="isSelectedDoorType">
  <div class="col-12 text-center">
    <h2>{{'DOOR_TYPE' | translate}}</h2>
  </div>
  <div class="col-12 lg:col-3 xl:col-3" *ngFor="let door of doors; index as i">
    <div class="card mb-0 onMouseOver" (click)="selectDoorType(door)">
      <div class="flex justify-content-between">
        <div class="mobil-image-area">
          <img [src]="door.image" class="mobil-image">
          <span class="block text-500 font-medium mb-3 text-center text-2xl">{{door.title | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!--- Door Type End-->
<!---  Dimensions -->
<div class="grid p-fluid justify-content-center mt-8" *ngIf="isSelectedDimensions">
  <div class="col-12 text-center">
    <h2>{{'DIMENSIONS' | translate}}</h2>
  </div>
  <div class="col-12 lg:col-3 xl:col-3">
    <div class="card mb-0 onMouseOver">
      <div class="flex justify-content-between text-center">
        <div class="mobil-image-area">
          <img [src]="'assets/img/door/width.jpg'" class="mobil-image">
          <label class="text-2xl text-black-alpha-50">{{'WIDTH' | translate}} (cm)</label>
          <p-inputNumber [(ngModel)]="width" class="dimension-input" mode="decimal" [minFractionDigits]="1" inputId="locale-user"
                         [useGrouping]="false" [locale]="'ar'">
          </p-inputNumber>
          <span class="p-error" *ngIf="widthError">{{'WIDTH_ERROR' | translate}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 lg:col-3 xl:col-3">
    <div class="card mb-0 onMouseOver">
      <div class="flex justify-content-between text-center">
        <div class="mobil-image-area">
          <img [src]="'assets/img/door/height.jpg'" class="mobil-image">
          <label class="text-2xl text-black-alpha-50">{{'HEIGHT' | translate}} (cm) </label>
          <p-inputNumber class="dimension-input" [(ngModel)]="height" mode="decimal" [minFractionDigits]="1" inputId="locale-user"
                         [useGrouping]="false" >
          </p-inputNumber>

          <span class="p-error" *ngIf="heightError">{{'HEIGHT_ERROR' | translate}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 lg:col-3 xl:col-3">
    <div class="card mb-0 onMouseOver">
      <div class="flex justify-content-between text-center">
        <div class="mobil-image-area-thickness">
          <img [src]="'assets/img/door/thickness.jpg'" class="mobil-image">
          <label class="text-2xl text-black-alpha-50">{{'PANEL_THICKNESS' | translate}} (cm)</label><br>
          <div class="flex justify-content-around"  >
            <div class="{{ thickness === thick.value ? 'thicness-active-button' : 'thicness-button'}}"
                 *ngFor="let thick of thicknesses" (click)="thickness = thick.value; width !== undefined && height !== undefined ? runCalculate() : ''"> {{ thick.value}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="grid p-fluid justify-content-center mt-8" *ngIf="isSelectedDimensions">
  <div class="col-12 lg:col-3 xl:col-3" *ngIf="width !== undefined && height !== undefined && thickness !== undefined ">
    <div class="flex justify-content-center text-center mrgt-50">
      <div style="width: 60%">
        <p-button label="{{'CONFIRM' | translate}}" (onClick)="selectDimensions()" icon="pi pi-check"
                  [style]="{width: '100%', background:'rgb(56 195 40)'}"></p-button>
      </div>
    </div>

  </div>
</div>
<!---  Dimensions End -->
<!---  Eşikli/eşiksiz -->
<div class="grid justify-content-center mt-8" *ngIf="isSelectedThreshold">
  <div class="col-12 text-center">
    <h2>{{'PASSAGE_TYPE' | translate}}</h2>
  </div>
  <div class="col-12 lg:col-3 xl:col-3" *ngFor="let thresholdType of thresholdTypes; index as i">
    <div class="card mb-0 onMouseOver" (click)="selectThresholdType(thresholdType)">
      <div class="flex justify-content-between">
        <div class="mobil-image-area">
          <img [src]="  thresholdType.image" class="mobil-image">
          <span class="block text-500 font-medium mb-3 text-center text-2xl">{{thresholdType.title | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!---  Eşikli/eşiksiz -->
<!---  Tek/Çift Kanat -->
<div class="grid justify-content-center mt-8" *ngIf="isSelectedDoorLeafFlap">
  <div class="col-12 text-center">
    <h2>{{'WING_TYPE' | translate}}</h2>
  </div>
  <div class="col-12 lg:col-3 xl:col-3" *ngFor="let wing of doorLeafFlaps; index as i">
    <div class="card mb-0 onMouseOver" (click)="selectWingType(wing)">
      <div class="flex justify-content-between">
        <div class="mobil-image-area">
          <img [src]="  wing.image" class="mobil-image">
          <span class="block text-500 font-medium mb-3 text-center text-2xl">{{wing.title | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!---  Tek/Çift Son -->
<!---  Temperature -->
<div class="grid justify-content-center mt-8" *ngIf="isSelectedTemperature">
  <div class="col-12 text-center">
    <h2>{{'COLD_ROOM_TEMPERATURE' | translate}}</h2>
  </div>
  <div class="col-12 lg:col-3 xl:col-3" *ngFor="let temperature of temperatures; index as i">

    <div class="card mb-0 onMouseOver" *ngIf="temperature.id == 3 && thickness === 15 || temperature.id == 3 && thickness === 12 " (click)="selectTemperature(temperature)">
      <div class="flex justify-content-between text-center">
        <div class="mobil-image-area">
          <img [src]="  temperature.image" class="mobil-image">
          <span class="block text-800 font-medium mb-3 text-center text-2xl">{{temperature.title | translate}}</span>
        </div>
      </div>
    </div>
    <div class="card mb-0 onMouseOver"*ngIf="temperature.id !== 3" (click)="selectTemperature(temperature)">
      <div class="flex justify-content-between text-center">
        <div class="mobil-image-area">
          <img [src]="  temperature.image" class="mobil-image">
          <span class="block text-800 font-medium mb-3 text-center text-2xl">{{temperature.title | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!---  Temperature End -->
<!---  Hinged Door Case Type -->
<div class="grid justify-content-center mt-8" *ngIf="isSelectedHingedDoorsCases">
  <div class="col-12 text-center">
    <h2>{{'CASE_TYPE' | translate}}</h2>
  </div>
  <div class="col-12 lg:col-3 xl:col-3" *ngFor="let hingedDoorsCase of hingedDoorsCases; index as i">
    <div class="card mb-0 onMouseOver" (click)="selectHingedDoorsCase(hingedDoorsCase)">
      <div class="flex justify-content-between text-center">
        <div class="mobil-image-area">
          <img [src]="  hingedDoorsCase.image" class="mobil-image">
          <span
            class="block text-800 font-medium mb-3 text-center text-2xl">{{hingedDoorsCase.title | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!---  Temperature End -->
<!---  Sliding Door Case Type -->
<div class="grid justify-content-center mt-8" *ngIf="isSelectedSlidingDoorsCases">
  <div class="col-12 text-center">
    <h2>{{'CASE_TYPE' | translate}}</h2>
  </div>
  <div class="col-12 lg:col-3 xl:col-3" *ngFor="let slidingDoorsCase of slidingDoorsCases; index as i">
    <div class="card mb-0 onMouseOver" (click)="selectSlidingDoorsCase(slidingDoorsCase)">
      <div class="flex justify-content-between text-center">
        <div class="mobil-image-area">
          <img [src]="  slidingDoorsCase.image" class="mobil-image">
          <span
            class="block text-800 font-medium mb-3 text-center text-2xl">{{slidingDoorsCase.title | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!---  Temperature End -->
<!---  doorLeafs -->
<div class="grid justify-content-center mt-8" *ngIf="isSelectedDoorLeaf">
  <div class="col-12 text-center">
    <h2>{{'LEAF_TYPE' | translate}}</h2>
  </div>
  <div class="col-12 lg:col-3 xl:col-3" *ngFor="let doorLeaf of doorLeafs; index as i">
    <div class="card mb-0 onMouseOver" (click)="selectDoorLeaf(doorLeaf)">
      <div class="flex justify-content-between text-center">
        <div class="mobil-image-area">
          <img [src]="  doorLeaf.image" class="mobil-image">
          <span class="block text-800 font-medium mb-3 text-center text-2xl">{{doorLeaf.title | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!---  doorLeafs End -->

<!---Quantity-->
<div class="grid p-fluid justify-content-center " *ngIf="isSelectedQuantity">
  <div class="col-12 text-center">
    <h2>{{'PRODUCE_QUANTITY' | translate}}</h2>
  </div>
  <div class="col-12 lg:col-3 xl:col-3">
    <div class="card mb-0 onMouseOver">
      <div class="flex justify-content-between text-center">
        <div class="mobil-image-area-thickness">
          <img [src]="'assets/img/door/quantity.jpeg'" class="mobil-image">
          <label class="text-2xl text-black-alpha-50">{{'QUANTITY' | translate}}</label>
          <p-inputNumber [(ngModel)]="quantity" class="dimension-input" mode="decimal" inputId="withoutgrouping"
                         [useGrouping]="false" (onBlur)="showControl();runCalculate()"
                         (keydown)="onEnter($event)">
          </p-inputNumber>
        </div>
      </div>
    </div>
  </div>
</div>
<!---Quantity End-->

<!---Order/cutting List-->
<div class="grid p-fluid justify-content-center " style="margin-top: 15%;"
     *ngIf="cuttingItems.length > 0 && quantity !== undefined && height !!= undefined && width !!= undefined">
  <div class="col-12 lg:col-3 xl:col-3">
    <div class="card mb-0 onMouseOver">
      <div class="flex justify-content-center text-center" (click)="cuttingListDisplay = true">
        <div>
          <label class="text-6xl text-black-alpha-50 text-center">{{'CUTTING_DIMENSIONS' | translate}}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 lg:col-3 xl:col-3">
    <div class="card mb-0 onMouseOver">
      <div class="flex justify-content-center text-center" (click)="orderListDisplay = true">
        <div>
          <label class="text-6xl text-black-alpha-50 text-center">{{'ORDER_LIST' | translate}}</label>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="{{'CUTTING_DIMENSIONS' | translate}}" [(visible)]="cuttingListDisplay" [style]="{width: '90vw'}">
  <p-button label="{{'EXPORT_PDF' | translate}}" icon="pi pi-check" (onClick)="downloadPDF()"></p-button>
  <p-card id="printCuttingList">
    <div class="add-div mobil-print">
      <div class="grid justify-content-start">
        <div class="col-4">
          <img src="assets/img/logo.png" alt="logo" style="width: 400px">
          <br>
          {{'PRODUCT_CALCULATER' | translate}}
        </div>
        <div class="col-8">
          <div class="grid p-fluid justify-content-center">
            <div class="col-6 text-left flex">
              <div class="col-4 text-left"><b>{{'DOOR_TYPE' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">: {{doorTypeTitle  | translate}}</div>
            </div>
            <div class="col-6 text-left flex">
              <div class="col-4 text-left"><b>{{'DIMENSIONS' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">
            <span *ngIf="width !== undefined && height !== undefined && thickness !== undefined ">
            : {{width}}-{{height}}-{{thickness}} cm </span>
              </div>
            </div>
            <div class="col-6 text-left flex">
              <div class="col-4 text-left"><b>{{'COLD_ROOM_TEMPERATURE' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">: {{temperatureTitle | translate}}</div>
            </div>
            <div class="col-6 text-left flex">
              <div class="col-4 text-left"><b>{{'PASSAGE_TYPE' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">: {{thresholdTypeTitle | translate}}</div>
            </div>
            <div class="col-6 text-left flex" *ngIf="doorType === 1">
              <div class="col-4 text-left"><b>{{'CASE_TYPE' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">: {{hingedDoorsCaseTitle  | translate}}</div>
            </div>
            <div class="col-6 text-left flex" *ngIf="doorType === 2">
              <div class="col-4 text-left"><b>{{'CASE_TYPE' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">: {{ slidingDoorsCaseTitle | translate}}</div>
            </div>
            <div class="col-6 text-left flex" *ngIf="doorType === 2">
              <div class="col-4 text-left"><b>{{'LEAF_TYPE' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">: {{doorLeafTitle  | translate}}</div>
            </div>
            <div class="col-6 text-left flex">
              <div class="col-4 text-left"><b>{{'LEAF_TYPE' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">: {{doorLeafTitle | translate}}</div>
            </div>
            <div class="col-6 text-left flex">
              <div class="col-4 text-left"><b>{{'PRODUCE_QUANTITY' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">: {{quantity}} {{ 'PIECE' | translate}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid justify-content-start">
      <div class="col-12 text-left mobil-print">
        <h2 class="mt-4 mb-4">{{'CUTTING_DIMENSIONS' | translate}}</h2>
        <p-table [value]="cuttingItems" sortField="item.id" sortMode="single" (onSort)="onSort()">
          <ng-template pTemplate="header">
            <tr>
              <th style="width:2%">#</th>
              <th style="width:12%">{{'IMAGE' | translate}}</th>
              <th style="width:12%">{{'NAME' | translate}}</th>
              <th style="width:20%">{{'SIDE' | translate}}</th>
              <th style="width:10%">{{'CASE_TYPE' | translate}}</th>
              <th style="width:20%">{{'SIDE_CUTTING_TYPE' | translate}}</th>
              <th style="width:10%;text-align: center">{{'CUTTING_DIMENSION' | translate}}</th>
              <th style="width:10%;text-align: center">{{'TOTAL' | translate}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
            <tr *ngIf="thickness !== undefined && item.height.includes(thickness)">
              <td>{{rowIndex + 1}}</td>
              <td *ngIf="rowGroupMetadata[item.product.name].index === rowIndex"
                  [attr.rowspan]="rowGroupMetadata[item.product.name].size" class="text-center">
                <img [alt]="item.product.name" src="{{item.product.image}}" *ngIf="item.product.image" width="150" style="vertical-align: middle"/>
              </td>
              <td *ngIf="rowGroupMetadata[item.product.name].index === rowIndex"
                  [attr.rowspan]="rowGroupMetadata[item.product.name].size">
                <span class="p-text-bold p-ml-2"> <b>{{item.product.code}}</b><br>{{item.product.name | translate}}</span></td>
              <td>
                {{item.name | translate}}
              </td>
              <td *ngIf="rowGroupMetadata[item.product.name].index === rowIndex"
                  [attr.rowspan]="rowGroupMetadata[item.product.name].size">
                <img [alt]="item.product.name" src="{{item.product.cuttingImage}}" *ngIf="item.product.cuttingImage" width="100"
                     style="vertical-align: middle"/>
              </td>
              <td>
                <img [alt]="item.name" src="{{item.image}}" *ngIf="item.image" style="vertical-align: middle"/>

              </td>
              <td style="text-align:right" *ngIf="item.type === 'horizontal'">
                {{thickness === 6 && item.decrease ? (item.value - item.decrease) + width  : item.value + (item.divided ? width / 2 : width) }} {{ item.product.unit}}
              </td>
              <td style="text-align:right" *ngIf="item.type === 'vertical'">
                {{thickness === 6 && item.decrease ? (item.value - item.decrease) + height  : item.value + height }} {{ item.product.unit}}
              </td>
              <td style="text-align:right" *ngIf="item.type === 'length'">
                {{item.value }} {{ item.product.unit}}
              </td>
              <td style="text-align:right" *ngIf="item.repeat === false">
                <span
                  [class]="'customer-badge status-' + item.value">{{item.quantity * quantity}} {{ 'PIECES' | translate}}</span>
              </td>
              <td style="text-align:right" *ngIf="item.repeat === true">
                <span [class]="'customer-badge status-' + item.value"
                      *ngIf="thickness === 6">{{item.quantity * quantity}} {{ 'PIECES' | translate}}</span>
                <span [class]="'customer-badge status-' + item.value"
                      *ngIf="thickness === 8">{{item.quantity * quantity}} {{ 'PIECES' | translate}}</span>
                <span [class]="'customer-badge status-' + item.value"
                      *ngIf="thickness === 10">{{item.quantity * 1 * quantity}} {{ 'PIECES' | translate}}</span>
                <span [class]="'customer-badge status-' + item.value"
                      *ngIf="thickness === 12">{{item.quantity * 2 * quantity}} {{ 'PIECES' | translate}}</span>
                <span [class]="'customer-badge status-' + item.value"
                      *ngIf="thickness === 15">{{item.quantity * 2 * quantity}} {{ 'PIECES' | translate}}</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </p-card>
</p-dialog>
<p-dialog header="{{'ORDER_LIST' | translate}}" [(visible)]="orderListDisplay" [style]="{width: '90vw'}">
  <div class="grid" style="padding: 10px">
    <p-button label="{{'EXPORT_PDF' | translate}}" icon="pi pi-check" (onClick)="downloadOrderPDF()"></p-button>
    <!---<p-button label="{{'SEND_ORDER' | translate}}" icon="pi pi-check" (onClick)="downloadAndSendOrderPDF()"></p-button>--->
  </div>
  <div id="printOrderList">
    <div class="add-div mobil-print">
      <div class="grid justify-content-start">
        <div class="col-4">
          <img src="assets/img/logo.png" alt="logo" style="width: 400px">
          <br>
          {{'PRODUCT_CALCULATER' | translate}}
        </div>
        <div class="col-8">
          <div class="grid p-fluid justify-content-center">
            <div class="col-6 text-left flex">
              <div class="col-4 text-left"><b>{{'DOOR_TYPE' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">: {{doorTypeTitle  | translate}}</div>
            </div>
            <div class="col-6 text-left flex">
              <div class="col-4 text-left"><b>{{'DIMENSIONS' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">
            <span *ngIf="width !== undefined && height !== undefined && thickness !== undefined ">
            : {{width}}-{{height}}-{{thickness}} cm </span>
              </div>
            </div>
            <div class="col-6 text-left flex">
              <div class="col-4 text-left"><b>{{'COLD_ROOM_TEMPERATURE' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">: {{temperatureTitle | translate}}</div>
            </div>
            <div class="col-6 text-left flex">
              <div class="col-4 text-left"><b>{{'PASSAGE_TYPE' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">: {{thresholdTypeTitle | translate}}</div>
            </div>
            <div class="col-6 text-left flex" *ngIf="doorType === 1">
              <div class="col-4 text-left"><b>{{'CASE_TYPE' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">: {{hingedDoorsCaseTitle  | translate}}</div>
            </div>
            <div class="col-6 text-left flex" *ngIf="doorType === 2">
              <div class="col-4 text-left"><b>{{'CASE_TYPE' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">: {{ slidingDoorsCaseTitle | translate}}</div>
            </div>
            <div class="col-6 text-left flex" *ngIf="doorType === 2">
              <div class="col-4 text-left"><b>{{'LEAF_TYPE' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">: {{doorLeafTitle  | translate}}</div>
            </div>
            <div class="col-6 text-left flex">
              <div class="col-4 text-left"><b>{{'LEAF_TYPE' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">: {{doorLeafTitle | translate}}</div>
            </div>
            <div class="col-6 text-left flex">
              <div class="col-4 text-left"><b>{{'PRODUCE_QUANTITY' | translate}}</b></div>
              <div class="col-8 text-left text-2xl">: {{quantity}} {{ 'PIECE' | translate}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h2 class="mt-4 mb-4">{{'ORDER_LIST' | translate}}</h2>
    <p-table [value]="orderItems" [tableStyle]="{'min-width': '50rem'}">
      <ng-template pTemplate="header">
        <tr>
          <th [style]="{width:'3%'}">{{'ROW_NUMBER' |translate}}</th>
          <th [style]="{width:'7%'}">{{'IMAGE' |translate}}</th>
          <th [style]="{width:'15%'}">{{'CODE' |translate}}</th>
          <th [style]="{width:'65%'}">{{'NAME' |translate}}</th>
          <th [style]="{width:'10%'}">{{'QUANTITY' |translate}}</th>
          <th [style]="{width:'5%'}">{{'UNIT' |translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-order let-i="rowIndex">
        <tr>
          <td>{{i + 1}}</td>
          <td><img [src]="  order.image" width="100"></td>
          <td>{{order.code}}</td>
          <td>{{(order.title? order.title : order.name) | translate}}
            <br> {{ order.dimensions ? order.dimensions : ''}}
          </td>
          <td *ngIf="doorType === 3 || doorType === 2">{{( order.quantity2 ? order.quantity2 * quantity : order.calculate ? getOrderQuantity(order.code) : order.quantity * quantity) | number: '1.2-2' }}</td>
          <td *ngIf="doorType === 1 && height > 209 && temperature === 1">{{ (order.quantity2 ? order.quantity2 * quantity :  order.calculate ? getOrderQuantity(order.code) : order.quantity * quantity) | number: '1.2-2' }}</td>
          <td *ngIf="doorType === 1 && height < 209 && temperature === 1">
            {{ (order.calculate ? getOrderQuantity(order.code) :
            (order.quantity * quantity % 1 !== 0 ? order.quantity * quantity  : (order.quantity * quantity))) | number: '1.2-2' }}
          </td>
          <td *ngIf="doorType === 1 && height > 195 && temperature !== 1">{{ (order.calculate ? getOrderQuantity(order.code) : order.quantity * quantity) | number: '1.2-2' }}</td>
          <td *ngIf="doorType === 1 && height < 195 && temperature !== 1">{{( order.calculate ? getOrderQuantity(order.code) : order.quantity * quantity) | number: '1.2-2' }}</td>

          <td>{{ (order.unit === 'cm' ? 'METER' : order.unit) | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>
