import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private data = new BehaviorSubject([]);
  sharedData = this.data.asObservable();

  constructor(private translate: TranslateService) {
  }
  startTimer(): void {
    setInterval(() => {
      const newData = [{ name: 'STOCK_REMAINING',
        title: this.translate.instant('STOCK_IMPORT_REMAINING'),
        url: 'aaaaaaa'}];
      this.setData(newData);
    }, 2000);
  }

  setData(data): void {
    this.data.next(data);
  }

}
