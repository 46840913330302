import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {AuthGuard} from './auth/auth.guard';
import {MenubarModule} from 'primeng/menubar';
import {SplitButtonModule} from 'primeng/splitbutton';
import {MegaMenuModule} from 'primeng/megamenu';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TableModule} from 'primeng/table';
import {SidebarModule} from 'primeng/sidebar';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {InputNumberModule} from 'primeng/inputnumber';
import { SelectButtonModule } from 'primeng/selectbutton';
import {CheckboxModule} from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import {EditorModule} from 'primeng/editor';
import {DividerModule} from 'primeng/divider';
import { DialogModule} from 'primeng/dialog';
import { ProgressSpinnerModule} from 'primeng/progressspinner';
import { ChipsModule} from 'primeng/chips';
import { CalendarModule} from 'primeng/calendar';
import { AutoCompleteModule} from 'primeng/autocomplete';
import { MultiSelectModule} from 'primeng/multiselect';
import { TagModule} from 'primeng/tag';
import { TabMenuModule} from 'primeng/tabmenu';
import { FileUploadModule} from 'primeng/fileupload';
import { TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { FlashMessagesComponent } from './component/flash-messages/flash-messages.component';
import { ToastModule} from 'primeng/toast';
import { OverlayPanelModule} from 'primeng/overlaypanel';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { ChartModule} from 'primeng/chart';
import { InplaceModule} from 'primeng/inplace';
import { PasswordModule} from 'primeng/password';
import { SplitterModule} from 'primeng/splitter';
import { MenuModule} from 'primeng/menu';
import { MessageModule} from 'primeng/message';
import { MessagesModule} from 'primeng/messages';
import { TooltipModule} from 'primeng/tooltip';
import { CaptchaModule} from 'primeng/captcha';
import { FullCalendarModule} from '@fullcalendar/angular';
import { InputSwitchModule} from 'primeng/inputswitch';
import { TreeTableModule} from 'primeng/treetable';
import { TabViewModule } from 'primeng/tabview';
import { DatePipe} from '@angular/common';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PanelModule} from 'primeng/panel';
import { AvatarModule } from 'primeng/avatar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ChipModule} from 'primeng/chip';
import {InputMaskModule} from 'primeng/inputmask';
import {StepsModule} from 'primeng/steps';
import {CarouselModule} from 'primeng/carousel';
import {BadgeModule} from 'primeng/badge';
import {SkeletonModule} from 'primeng/skeleton';
import {DialogService} from 'primeng/dynamicdialog';
import {RippleModule} from 'primeng/ripple';
import {ToolbarModule} from 'primeng/toolbar';
import {PaginatorModule} from 'primeng/paginator';
import {DataViewModule} from 'primeng/dataview';
import {TimelineModule} from "primeng/timeline";
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';
import {AccordionModule} from "primeng/accordion";

// Arapça dil ayarlarını kaydedin
registerLocaleData(localeAr);


@NgModule({
  declarations: [
    AppComponent,
    FlashMessagesComponent,
    DashboardComponent,
  ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        InputTextModule,
        CardModule,
        ButtonModule,
        HttpClientModule,
        FormsModule,
        MenubarModule,
        SplitButtonModule,
        MegaMenuModule,
        TableModule,
        SidebarModule,
        ConfirmPopupModule,
        ConfirmDialogModule,
        BrowserAnimationsModule,
        InputNumberModule,
        SelectButtonModule,
        CheckboxModule,
        DropdownModule,
        EditorModule,
        DividerModule,
        DialogModule,
        ProgressSpinnerModule,
        ChipsModule,
        CalendarModule,
        AutoCompleteModule,
        MultiSelectModule,
        TagModule,
        TabMenuModule,
        FileUploadModule,
        ToastModule,
        OverlayPanelModule,
        ChartModule,
        InplaceModule,
        PasswordModule,
        SplitterModule,
        MessageModule,
        MessagesModule,
        CaptchaModule,
        FullCalendarModule,
        InputSwitchModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: 'tr'
        }),
        MenuModule,
        TooltipModule,
        TreeTableModule,
        TabViewModule,
        TriStateCheckboxModule,
        PanelMenuModule,
        MenuModule,
        PanelModule,
        AvatarModule,
        RadioButtonModule,
        ChipModule,
        InputMaskModule,
        StepsModule,
        CarouselModule,
        BadgeModule,
        SkeletonModule,
        RippleModule,
        FullCalendarModule,
        ToolbarModule,
        PaginatorModule,
        DataViewModule,
        TimelineModule,
        AccordionModule
    ],
  providers: [TranslateService, AuthGuard, MessageService,
    ConfirmationService, TranslateService,
    DatePipe, DialogService, { provide: LOCALE_ID, useValue: 'ar' },],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
