import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  async login(email: string, password: string): Promise<any> {
    return await this.http.post(`${environment.apiUrl}/api/login_check`, {
      email,
      password
    }).toPromise().then(response => {
      // @ts-ignore
      window.localStorage.setItem('user', JSON.stringify(response));
      // @ts-ignore
      window.localStorage.setItem('token', response.token);

      return response;
    }).catch(error => {
      return error;
    });
  }


  async checkLogin(): Promise<any> {
    const token = window.localStorage.getItem('token');
    let r = null;

    await this.http.get(`${environment.apiUrl}/api/check_token/${token}`).toPromise().then(response => {
      r = response;
    });

    return r;
  }
}
