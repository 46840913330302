<app-flash-messages xmlns="http://www.w3.org/1999/html"></app-flash-messages>

<div class="layout-wrapper">
  <div class="layout-topbar">

    <a class="layout-topbar-logo" routerLink="">
      <img src="assets/img/logo.png" alt="logo"><br>
    </a>
    <i class="pi pi-align-justify" style="width: 50px; font-size: 25px" (click)="toggleMenu()"></i>
    {{'PRODUCT_CALCULATER' | translate}}
    <div #topbarmenu [ngClass]="{'block': isMenuVisible, 'none': !isMenuVisible}" class="layout-topbar-menu">
      <p-dropdown [options]="countries" [(ngModel)]="selectedCountry" optionLabel="name" optionValue="code"
                  (onChange)="changeLanguage($event.value)" [style]="{width:'200px'}">
      </p-dropdown>
      <button class="p-link layout-topbar-button">
        <i class="pi pi-user" (click)="userDisplay = true"></i>
        <span>TR</span>
      </button>
    </div>
  </div>


  <div class="layout-main-container">
    <div class="layout-main">
      <router-outlet></router-outlet>
    </div>
    <div class="layout-footer">
      by
      <span class="font-medium ml-2">NetPlas</span>
    </div>
  </div>
  <div class="layout-mask"></div>
</div>

<p-dialog [(visible)]="userDisplay" [style]="{width: '50vw'}" (onHide)="save()">
  <form (submit)="save()">
    <div class="grid">
      <div class="col-12 flex flex-column align-items-center mb-8">
        <img src="assets/img/logo.png" alt="logo"><br>
        <h4>{{'WELLCOME_PRODUCT_CALCULATER' | translate}}</h4>
        <p>{{'PRODUCT_CALCULATER_DESCRIPTION' | translate}}</p>
      </div>
      <div class="col-12 alert-div" *ngIf="isError">
        <p>{{'ERROR_VALIDATION' | translate}}</p>
      </div>
      <div class="col-6 mt-4">
      <span class="p-float-label">
          <input id="USER_NAME" name="USER_NAME" type="text" pInputText [(ngModel)]="user.name" [style]="{width: '100%'}">
          <label for="USER_NAME">{{'USER_NAME' | translate}}</label>
      </span>
      </div>
      <div class="col-6 mt-4">
      <span class="p-float-label">
          <input id="COMPANY_NAME" name="COMPANY_NAME" type="text" pInputText [(ngModel)]="user.companyName" [style]="{width: '100%'}">
          <label for="COMPANY_NAME">{{'COMPANY_NAME' | translate}}</label>
      </span>
      </div>
      <div class="col-6 mt-4">
      <span class="p-float-label">
          <input id="PHONE" name="PHONE" type="text" pInputText [(ngModel)]="user.phone" [style]="{width: '100%'}">
          <label for="PHONE">{{'PHONE' | translate}}</label>
      </span>
      </div>
      <div class="col-6 mt-4">
      <span class="p-float-label">
          <input id="EMAIL" name="EMAIL" type="text" pInputText [(ngModel)]="user.email" [style]="{width: '100%'}">
          <label for="EMAIL">{{'EMAIL' | translate}}</label>
      </span>
      </div>
      <div class="col-12 mt-4">
      <span class="p-float-label">
          <input id="ADDRESS" name="ADDRESS" type="text" pInputText [(ngModel)]="user.address" [style]="{width: '100%'}">
          <label for="ADDRESS">{{'ADDRESS' | translate}}</label>
      </span>
      </div>
      <div class="col-12">
        <button type="submit" pButton label="{{'SAVE' | translate}}" ></button>
      </div>
    </div>
  </form>
</p-dialog>

