import {Component, OnInit, ElementRef} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {AuthService} from './service/auth.service';
import {environment} from '../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {UserModel} from './model/user.model';
import {HttpService} from './service/http.service';
import {Router} from '@angular/router';
import 'primeflex/primeflex.css';
import {DialogModule} from 'primeng/dialog';
import {PrimeNGConfig} from 'primeng/api';
import {NotificationService} from './service/notification.service';
import {UserTypeService} from './service/user-type.service';
import {LangService} from './service/lang.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  countries: any[]= []
  isError: boolean
  user= {
    name :'',
    email :'',
    address :'',
    companyName :'',
    phone :'',
    password :'',
  }
  selectedCountry: string;
  userDisplay: boolean;
  isMenuVisible = false;


  constructor(private translate: TranslateService,
              private authService: AuthService,
              private http: HttpService,
              private router: Router,
              private element: ElementRef,
              private config: PrimeNGConfig,
              private notificationService: NotificationService,
              private userTypeService: UserTypeService,
              private langService: LangService) {
    const language = localStorage.getItem('language');

    if (language) {
      this.translate.setDefaultLang(localStorage.getItem('language'));
      this.translate.use(localStorage.getItem('language'));
    }

  }

  ngOnInit(): void {
    if(JSON.parse(localStorage.getItem('user'))){
      this.user = JSON.parse(localStorage.getItem('user'));
    }

    this.countries = [
      {name: 'Türkçe', code: 'tr'},
      {name: 'English', code: 'en'},
      {name: 'عربي', code: 'ar'}
    ];

    this.selectedCountry = this.countries[0];
    if(!this.user.companyName){
      this.userDisplay = true;
    }
  }
  logout(): void {
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('token');
    window.location.href = '/login';
  }

  changeLanguage(language) {
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    localStorage.setItem('language', language);
  }
  save() {
    this.isError = false;
    if(this.user.name === ''){
      this.isError = true;
    }
    if(this.user.companyName === ''){
      this.isError = true;
    }
    if(this.user.phone === ''){
      this.isError = true;
    }
    if(this.user.email === ''){
      this.isError = true;
    }
    if(this.user.address === ''){
      this.isError = true;
    }
    if(this.isError === false){
      localStorage.setItem('user',  JSON.stringify(this.user));
      this.userDisplay = false
    }
  }
  reload(): void {
    window.location.reload();
  }

  toggleMenu(): void {
    this.isMenuVisible = !this.isMenuVisible;
  }



}
