import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor() { }

  async downloadAndSendOrderPDF(to: string, subject: string, message: string): Promise<void> {
    const content = document.getElementById('printOrderList') as HTMLElement; // PDF içeriği

    try {
      const canvas = await html2canvas(content);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth * 0.9, pdfHeight);

      // PDF dosyasını Blob olarak oluştur
      const pdfBlob = pdf.output('blob');

      // PDF dosyasını sunucuya yükle
      const formData = new FormData();
      formData.append('file', pdfBlob, 'order.pdf');

      const response = await fetch('/app/upload', { // Sunucu URL'sini değiştir
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('PDF sunucuya yüklenirken bir hata oluştu');
      }

      // E-posta göndermek için sunucudaki bir API'yi çağır
      const emailResponse = await fetch('/api/send-email', { // Sunucu URL'sini değiştir
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          to: to,
          subject: subject,
          message: message,
          file: 'order.pdf'
        })
      });

      if (!emailResponse.ok) {
        throw new Error('E-posta gönderilirken bir hata oluştu');
      }

      alert('PDF başarıyla e-posta gönderildi!');
    } catch (error) {
      console.error(error);
      alert('Bir hata oluştu. Lütfen tekrar deneyin.');
    }
  }
}
