import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {ApiResourceModel} from '../model/api-resource.model';
import {ValidationService} from './validation.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient, private router: Router,
              private messageService: MessageService, private validationService: ValidationService) { }

  header(): HttpHeaders {

    const token = window.localStorage.getItem('token');
/*
    if (token) {
      return new HttpHeaders({
        Authorization: `Bearer ${token}`
      });
    }
*/
    return new HttpHeaders();

  }

  downloadHeader(): HttpHeaders {
    const token = window.localStorage.getItem('token');

    if (token) {
      return new HttpHeaders({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
    }
  }

  get(url: string, params: {} = null): any {
    return this.httpClient.get(`${environment.apiUrl}${url}`, {
      params
    });
  }

  download(url: string, params: {} = null): any {
    return this.httpClient.get(`${environment.apiUrl}${url}`, {
      headers: this.downloadHeader(),
      params,
      responseType: 'blob' as 'json',
    });
  }

  put(url, data): any {
    return this.httpClient.put(`${environment.apiUrl}${url}`, data, {
      headers: this.header()
    }).toPromise().then((response: ApiResourceModel) => {
      return response;
    }).catch(response => {
      if (response.status === 400 || response.status === 422) {
        const errors = {};
        if (response.error.violations && response.error.violations.length > 0) {
          response.error.violations.map((item) => {
            this.messageService.add({severity: 'error', summary: `${item.propertyPath}: ${item.message}`});
            errors[item.propertyPath] = item.message;
          });
          this.validationService.updateErrors(errors);
        } else {
          this.messageService.add({severity: 'error', summary: `${response.error['hydra:description']}`});
        }
      }
    });
  }

  delete(url): any {
    return this.httpClient.delete(`${environment.apiUrl}${url}`, {
      headers: this.header()
    }).toPromise().then(() => {});
  }

  post(url, data): any {
    return this.httpClient.post(`${environment.apiUrl}${url}`, data, {
      headers: this.header()
    }).toPromise().then((response: ApiResourceModel) => {
      return response;
    }).catch(response => {
      if (response.status === 400 || response.status === 422) {
        const errors = {};
        if (response.error.violations && response.error.violations.length > 0) {
          response.error.violations.map((item) => {
            this.messageService.add({severity: 'error', summary: `${item.propertyPath}: ${item.message}`});
            errors[item.propertyPath] = item.message;
          });
          this.validationService.updateErrors(errors);
        }
      } else if (response.status ) {
        this.messageService.add({severity: 'error', summary: `Bir hata oluştu lütfen tekrar deneyiniz`});
      }
    });
  }
}
