import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpService} from '../../service/http.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {MenuItem} from "primeng/api";
import {environment} from "../../../environments/environment";
// @ts-ignore
import * as jsonData from '../../../assets/db.json';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {PdfService} from "../../service/pdf-service.service";
import {formatNumber} from "@angular/common";
import {delay} from "rxjs/operators";
import {AccordionModule} from 'primeng/accordion';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild('content') content: ElementRef;
  // Arrays
  doors: any[] = []
  thicknesses: any[] = []
  thresholdTypes: any[] = []
  hingedDoorsCases: any[] = []
  slidingDoorsCases: any[] = []
  doorLeafs: any[] = []
  doorLeafFlaps: any[] = []
  currentCase: any[] = [];
  currentCaseCutting: any[] = [];
  test: any[] = [];
  cuttingItems: any[] = [];
  orderItems: any[] = [];


  doorType: number
  doorLeaf: number
  doorLeafFlap: number
  thresholdType: number
  hingedDoorsCase: number
  slidingDoorsCase: number
  temperatures: any[] = []
  temperature: number
  width: number
  height: number
  thickness: number
  quantity: number
  // booleans
  isSelectedDoorType: boolean
  isSelectedWidth: boolean
  isSelectedHeight: boolean
  isSelectedThickness: boolean
  isSelectedTemperature: boolean
  isSelectedThreshold: boolean
  isSelectedDimensions: boolean
  isSelectedHingedDoorsCases: boolean
  isSelectedDoorLeaf: boolean
  isSelectedDoorLeafFlap: boolean
  isSelectedSlidingDoorsCases: boolean
  isSelectedQuantity: boolean

  isSelected: boolean
  items: MenuItem[];
  baseUrl: string;
  // titles
  doorTypeTitle: any;
  temperatureTitle: any;
  hingedDoorsCaseTitle: any;
  thresholdTypeTitle: any;
  slidingDoorsCaseTitle: any;
  doorLeafTitle: any;
  doorLeafFlapTitle: any;
  rowGroupMetadata: any;
  tabMenuItems: MenuItem[];
  activeItem: MenuItem;
  loading: any;
  Part: any[] = []
  user = {
    name: '',
    email: '',
    address: '',
    companyName: '',
    phone: '',
    password: '',
  }
  cuttingListDisplay: boolean;
  orderListDisplay: boolean;
  widthError: boolean;
  heightError: boolean;


  constructor(private http: HttpService, private translate: TranslateService, ht: HttpClient, private pdfService: PdfService) {
  }

  ngOnInit(): void {

    this.loading = true;
    this.baseUrl = environment.apiUrl
    this.showControl()
    this.doors = jsonData.doors;
    this.temperatures = jsonData.temperatures;
    this.thresholdTypes = jsonData.thresholdTypes;
    this.thicknesses = jsonData.thickness;
    this.hingedDoorsCases = jsonData.hingedDoorsCases;
    this.doorLeafs = jsonData.doorLeafs;
    this.doorLeafFlaps = jsonData.doorLeafsFlaps;
    this.slidingDoorsCases = jsonData.slidingDoorsCases;


    const filteredResult = this.filterBottomNonHorizontal(jsonData);
    console.log(filteredResult);


    setTimeout(() => {
      this.loading = false;
      this.tabMenuItems = [
        {
          label: this.translate.instant('CUTTING_DIMENSIONS'), title: 'CUTTING_DIMENSIONS', command: () => {
            this.activeItem = this.tabMenuItems[0];
          }
        },
        {
          label: this.translate.instant('ORDER_LIST'), title: 'ORDER_LIST', command: () => {
            this.activeItem = this.tabMenuItems[1];
          }
        }

      ];
      this.activeItem = this.tabMenuItems[0];
    }, 3000);

    // Örnek kullanım
    /*
    const parts = [
      { code: 'SP-519', name: 'Profil 1', length: 2.5, count: 2 },
      { code: 'SP-519', name: 'Profil 1', length: 1.25, count: 1 },
    ];

    const result = this.calculateRequiredLengths(parts);

    for (const code in result) {
      console.log(`Code: ${code}`);
      console.log(`Gerekli boy sayısı: ${result[code].totalLengths}`);
      console.log('Detaylar:', result[code].details);
      console.log('Gruplandırılmış Detaylar:', result[code].groupedDetails);
    }
*/
    if (JSON.parse(localStorage.getItem('user'))) {
      this.user = JSON.parse(localStorage.getItem('user'));
    }
  }

  filterBottomNonHorizontal(data: any): any[] {
    let result: any[] = [];

    // JSON verisinde tüm alanları dolaşalım
    for (const key in data) {
      const value = data[key];

      // Eğer değer bir dizi ise
      if (Array.isArray(value)) {
        const filtered = value.filter((item: any) => item.name === "RIGHT_SIDE" && item.type !== "vertical");
        result = result.concat(filtered);
      }

      // Eğer değer bir nesne ise, içindeki dizileri kontrol edelim
      else if (typeof value === "object" && value !== null) {
        result = result.concat(this.filterBottomNonHorizontal(value)); // Recursive çağrı
      }
    }

    return result;
  }

  getOrderQuantity(code) {

    const parts = [];
    this.cuttingItems.forEach(product => {
      if (product.product.code === code) {
        let calculateQuantity = product.quantity * this.quantity
        if (product.repeat === true) {

          if (this.thickness === 10) {
            calculateQuantity = product.quantity * this.quantity * 1
          }
          if (this.thickness === 12) {
            calculateQuantity = product.quantity * this.quantity * 2
          }
          if (this.thickness === 15) {
            calculateQuantity = product.quantity * this.quantity * 2
          }

        }
        const item = {
          code: code,
          name: product.product.name,
          calculate: product.product.calculate,
          length: (product.type === 'vertical' ? product.value + this.height : product.value + this.width) / 100,
          count: calculateQuantity
        }
        parts.push(item)
      }
    });
    const result = this.calculateRequiredLengths(parts);
    for (const code in result) {
      return result[code].totalLengths * 6
    }
  }

  // @ts-ignore
  calculateRequiredLengths(parts: Part[]): { [code: string]: { totalLengths: number, details: { code: string, name: string, length: number }[][], groupedDetails: { parts: { code: string, name: string, length: number }[], count: number }[] } } {
    const maxLength = 6; // Boy uzunluğu 6 metre
    const result: { [code: string]: { totalLengths: number, details: { code: string, name: string, length: number }[][], groupedDetails: { parts: { code: string, name: string, length: number }[], count: number }[] } } = {};

    // Her bir code için parçaları gruplayın
    const groupedParts: { // @ts-ignore
      [code: string]: Part[] } = {};
    for (const part of parts) {
      if (!groupedParts[part.code]) {
        groupedParts[part.code] = [];
      }
      groupedParts[part.code].push(part);
    }

    // Her code için hesaplama yapın
    for (const code in groupedParts) {
      const codeParts = groupedParts[code];
      let totalLengths: { code: string, name: string, length: number }[] = [];

      // Her uzunluk ve miktar için parçaları diziye ekleyin
      for (const item of codeParts) {
        for (let i = 0; i < item.count; i++) {
          totalLengths.push({ code: item.code, name: item.name, length: item.length });
        }
      }

      // Parçaları uzunluklarına göre büyükten küçüğe doğru sıralayın
      totalLengths.sort((a, b) => b.length - a.length);

      let requiredLengths = 0;
      let details: { code: string, name: string, length: number }[][] = []; // Her boyun içerdiği parçaları tutan liste

      // Parçaları mümkün olduğunca 6 metrelik boylara sığdırın
      while (totalLengths.length > 0) {
        let currentLength = 0;
        let currentParts: { code: string, name: string, length: number }[] = [];

        // Her parçayı mevcut profile eklemeye çalışalım
        for (let i = 0; i < totalLengths.length; i++) {
          if (currentLength + totalLengths[i].length <= maxLength) {
            currentLength += totalLengths[i].length;
            currentParts.push(totalLengths[i]);
            totalLengths.splice(i, 1); // Eklenen parçayı listeden çıkart
            i--; // İndeksi güncelle
          }
        }

        // Eğer bir profil dolduysa say
        if (currentLength > 0) {
          requiredLengths++;
          details.push(currentParts);
        }
      }

      // Aynı kesim ölçülerine sahip boyları gruplandırın
      const groupedDetails: { parts: { code: string, name: string, length: number }[], count: number }[] = [];

      details.forEach(parts => {
        const existingGroup = groupedDetails.find(group =>
          group.parts.length === parts.length &&
          group.parts.every((value, index) =>
            value.length === parts[index].length &&
            value.code === parts[index].code &&
            value.name === parts[index].name
          )
        );

        if (existingGroup) {
          existingGroup.count++;
        } else {
          groupedDetails.push({ parts, count: 1 });
        }
      });

      result[code] = { totalLengths: requiredLengths, details: details, groupedDetails: groupedDetails };
    }

    return result;
  }


  showControl() {
    this.allHide()
    this.cuttingItems = [];
    this.orderItems = [];
    if (this.doorType === undefined) {
      this.isSelectedDoorType = true
      return;
    }
    if (this.width === undefined && this.height === undefined && this.thickness === undefined) {

      this.isSelectedDimensions = true;
      return;
    }
    if (this.temperature === undefined && this.doorType !== 3) {
      this.isSelectedTemperature = true;
      return;
    }

    if (this.doorType === 1) {
      if (this.thresholdType === undefined) {
        this.isSelectedThreshold = true;
        return;
      }

      if (this.hingedDoorsCase === undefined) {
        this.isSelectedHingedDoorsCases = true;
        return;
      }
      if (this.doorLeaf === undefined) {
        this.isSelectedDoorLeaf = true;
        return;
      }
    }
    if (this.doorType === 2) {
      if (this.slidingDoorsCase === undefined) {
        this.isSelectedSlidingDoorsCases = true;
        return;
      }
      if (this.doorLeaf === undefined) {
        this.isSelectedDoorLeaf = true;
        return;
      }
    }
    if (this.doorType === 3) {
      if (this.doorLeafFlap === undefined) {
        this.isSelectedDoorLeafFlap = true;
        return;
      }
    }

    if (this.quantity === undefined) {
      this.isSelectedQuantity = true
      return;
    }
    this.runCalculate()
  }


  runCalculate() {
    this.cuttingItems = []
    this.orderItems = [];
    // Kapı Tipi Menteşeli kapı
    if (this.doorType === 1) {
      this.orderItems.push(jsonData.HINGE_DOOR_ACCESSORIES[1]);
      delay(100)
      this.orderItems.push(jsonData.HINGE_DOOR_ACCESSORIES[0]);
      // Eşikli Kapı Kasası
      if (this.thresholdType === 1) {
        // Kasa
        if (this.hingedDoorsCase === 1) { // Kasa Tipi Plastik
          if (this.thickness === 6) {
            jsonData.SP094SERIES_THRESHOLD.map(item => {
              this.cuttingItems.push(item);
            });
            this.orderItems.push(jsonData.SP094SERIES_THRESHOLD[0].product);
            this.orderItems.push(jsonData.SP094SERIES_THRESHOLD[4].product);
            this.orderItems.push(jsonData.SP094SERIES_THRESHOLD[8].product);
          } else {
            if (this.temperature === 3) {
              jsonData.SP063SERIES_THRESHOLD.map(item => {
                this.cuttingItems.push(item);
              });

              this.orderItems.push(jsonData.SP063SERIES_THRESHOLD[0].product);
              this.orderItems.push(jsonData.SP063SERIES_THRESHOLD[4].product);
              this.orderItems.push(jsonData.SP063SERIES_THRESHOLD[8].product);
              this.orderItems.push(jsonData.SP063SERIES_THRESHOLD[12].product);
              this.orderItems.push(jsonData.SP063SERIES_THRESHOLD[16].product);
            } else {
              jsonData.SP0518SERIES_THRESHOLD.map((item, i) => {
                this.cuttingItems.push(item);
              });
              this.orderItems.push(jsonData.SP0518SERIES_NO_THRESHOLD[0].product);
              if (this.thickness > 8) {
                this.orderItems.push(jsonData.SP0518SERIES_NO_THRESHOLD[3].product);
              }
              if (this.thickness === 15) {
                this.orderItems.push(jsonData.SP0518SERIES_NO_THRESHOLD[6].product);
              }
              this.orderItems.push(jsonData.SP0518SERIES_NO_THRESHOLD[9].product);
              this.orderItems.push(jsonData.SP0518SERIES_NO_THRESHOLD[12].product);
            }
          }

        }
        if (this.hingedDoorsCase === 2) { // Kasa Tipi Aluminyum
          jsonData.SP101SERIES_THRESHOLD.map(item => {
            this.cuttingItems.push(item);
          });
          this.orderItems.push(jsonData.SP101SERIES_THRESHOLD[0].product);
          this.orderItems.push(jsonData.SP101SERIES_THRESHOLD[4].product);
          this.orderItems.push(jsonData.SP101SERIES_THRESHOLD[8].product);
        }
      }
      // Eşiksiz Kapı Kasası
      if (this.thresholdType === 2) { // Eşiksiz Kapı
        if (this.hingedDoorsCase === 1) { // Kasa Tipi Plastik
          if (this.thickness === 6) {
            jsonData.SP094SERIES_NO_THRESHOLD.map(item => {
              this.cuttingItems.push(item);
            });
            this.orderItems.push(jsonData.SP094SERIES_NO_THRESHOLD[0].product);
            this.orderItems.push(jsonData.SP094SERIES_NO_THRESHOLD[4].product);
            this.orderItems.push(jsonData.SP094SERIES_NO_THRESHOLD[8].product);
          } else {
            if (this.temperature === 3) {
              jsonData.SP063SERIES_NO_THRESHOLD.map(item => {
                this.cuttingItems.push(item);
              });

              this.orderItems.push(jsonData.SP063SERIES_NO_THRESHOLD[0].product);
              if (this.thickness > 8) {
                this.orderItems.push(jsonData.SP063SERIES_NO_THRESHOLD[4].product);
              }
              if (this.thickness === 15) {
                this.orderItems.push(jsonData.SP063SERIES_NO_THRESHOLD[8].product);
              }

              this.orderItems.push(jsonData.SP063SERIES_NO_THRESHOLD[12].product);
              // this.orderItems.push(jsonData.SP063SERIES_NO_THRESHOLD[16].product);
            } else {
              jsonData.SP0518SERIES_NO_THRESHOLD.map((item, i) => {
                this.cuttingItems.push(item);
              });
              this.orderItems.push(jsonData.SP0518SERIES_NO_THRESHOLD[0].product);
              if (this.thickness > 8) {
                this.orderItems.push(jsonData.SP0518SERIES_NO_THRESHOLD[3].product);
              }
              if (this.thickness === 15) {
                this.orderItems.push(jsonData.SP0518SERIES_NO_THRESHOLD[6].product);
              }
              this.orderItems.push(jsonData.SP0518SERIES_NO_THRESHOLD[9].product);
              this.orderItems.push(jsonData.SP0518SERIES_NO_THRESHOLD[12].product);
            }
          }

        }
        if (this.hingedDoorsCase === 2) { // Kasa Tipi Aluminyum
          jsonData.SP101SERIES_NO_THRESHOLD.map(item => {
            this.cuttingItems.push(item);
          });
          this.orderItems.push(jsonData.SP101SERIES_NO_THRESHOLD[0].product);
          this.orderItems.push(jsonData.SP101SERIES_NO_THRESHOLD[3].product);
          this.orderItems.push(jsonData.SP101SERIES_NO_THRESHOLD[6].product);
        }
      }

      // Kanat //////////////////////////////////////////////////////
      if (this.temperature === 1) {
        if (this.thresholdType === 1) {
          if (this.doorLeaf === 1) {
            jsonData.INJECTION_PANEL_POSITIVE_THRESHOLD.map(item => {
              this.cuttingItems.push(item);
            });
            // Order List
            this.orderItems.push(jsonData.INJECTION_PANEL_POSITIVE_THRESHOLD[0].product);
            this.orderItems.push(jsonData.INJECTION_PANEL_POSITIVE_THRESHOLD[4].product);
            this.orderItems.push(jsonData.INJECTION_PANEL_POSITIVE_THRESHOLD[8].product);
          }
          if (this.doorLeaf === 2) {
            jsonData.READY_PANEL_POSITIVE_THRESHOLD.map(item => {
              this.cuttingItems.push(item);
            });
            // Order List
            this.orderItems.push(jsonData.READY_PANEL_POSITIVE_THRESHOLD[0].product);
            this.orderItems.push(jsonData.READY_PANEL_POSITIVE_THRESHOLD[4].product);
            this.orderItems.push(jsonData.READY_PANEL_POSITIVE_THRESHOLD[8].product);
          }
        }
        if (this.thresholdType === 2) {
          // Enjeksiyon
          if (this.doorLeaf === 1) {
            jsonData.INJECTION_PANEL_POSITIVE_NO_THRESHOLD.map(item => {
              this.cuttingItems.push(item);
            });
            // Order List
            this.orderItems.push(jsonData.INJECTION_PANEL_POSITIVE_NO_THRESHOLD[0].product);
            this.orderItems.push(jsonData.INJECTION_PANEL_POSITIVE_NO_THRESHOLD[4].product);
            this.orderItems.push(jsonData.INJECTION_PANEL_POSITIVE_NO_THRESHOLD[7].product);
            // @ts-ignore
            jsonData.INJECTION_PANEL_POSITIVE_NO_THRESHOLD[11].product.quantity = (this.width + 7) / 100;
            this.orderItems.push(jsonData.INJECTION_PANEL_POSITIVE_NO_THRESHOLD[11].product);
          }
          // Hazır Panel
          if (this.doorLeaf === 2) {
            jsonData.READY_PANEL_POSITIVE_NO_THRESHOLD.map(item => {
              this.cuttingItems.push(item);
            });
            // Order List
            this.orderItems.push(jsonData.READY_PANEL_POSITIVE_NO_THRESHOLD[0].product);
            this.orderItems.push(jsonData.READY_PANEL_POSITIVE_NO_THRESHOLD[4].product);
            jsonData.READY_PANEL_POSITIVE_NO_THRESHOLD[7].product.quantity = (this.width + 7) / 100;
            this.orderItems.push(jsonData.READY_PANEL_POSITIVE_NO_THRESHOLD[7].product);
          }
        }
      }

      if (this.temperature === 2) {
        if (this.thresholdType === 1) {
          if (this.doorLeaf === 1) {
            jsonData.INJECTION_PANEL_NEGATIVE_THRESHOLD.map(item => {
              this.cuttingItems.push(item);
            });
            // Order List
            this.orderItems.push(jsonData.INJECTION_PANEL_NEGATIVE_THRESHOLD[0].product);
            this.orderItems.push(jsonData.INJECTION_PANEL_NEGATIVE_THRESHOLD[4].product);
            this.orderItems.push(jsonData.INJECTION_PANEL_NEGATIVE_THRESHOLD[8].product);
          }
          if (this.doorLeaf === 2) {
            jsonData.READY_PANEL_NEGATIVE_THRESHOLD.map(item => {
              this.cuttingItems.push(item);
            });
            // Order List
            this.orderItems.push(jsonData.READY_PANEL_NEGATIVE_THRESHOLD[0].product);
            this.orderItems.push(jsonData.READY_PANEL_NEGATIVE_THRESHOLD[4].product);
            this.orderItems.push(jsonData.READY_PANEL_NEGATIVE_THRESHOLD[8].product);
          }
        }
        if (this.thresholdType === 2) {
          if (this.doorLeaf === 1) {
            jsonData.INJECTION_PANEL_NEGATIVE_NO_THRESHOLD.map(item => {
              this.cuttingItems.push(item);
            });
            // Order List
            this.orderItems.push(jsonData.INJECTION_PANEL_NEGATIVE_NO_THRESHOLD[0].product);
            this.orderItems.push(jsonData.INJECTION_PANEL_NEGATIVE_NO_THRESHOLD[4].product);
            this.orderItems.push(jsonData.INJECTION_PANEL_NEGATIVE_NO_THRESHOLD[8].product);
            jsonData.INJECTION_PANEL_NEGATIVE_NO_THRESHOLD[11].product.quantity = (this.width + 7) / 100;
            this.orderItems.push(jsonData.INJECTION_PANEL_NEGATIVE_NO_THRESHOLD[11].product);
          }
          if (this.doorLeaf === 2) {
            jsonData.READY_PANEL_NEGATIVE_NO_THRESHOLD.map(item => {
              this.cuttingItems.push(item);
            });
            // Order List
            this.orderItems.push(jsonData.READY_PANEL_NEGATIVE_NO_THRESHOLD[0].product);
            this.orderItems.push(jsonData.READY_PANEL_NEGATIVE_NO_THRESHOLD[4].product);
            this.orderItems.push(jsonData.READY_PANEL_NEGATIVE_NO_THRESHOLD[7].product);

          }
        }
      }

      if (this.temperature === 3) {
        if (this.thresholdType === 1) {
          if (this.doorLeaf === 1) {
            jsonData.INJECTION_PANEL_SUPER_NEGATIVE_THRESHOLD.map(item => {
              this.cuttingItems.push(item);
            });
            // Order List
            this.orderItems.push(jsonData.INJECTION_PANEL_SUPER_NEGATIVE_THRESHOLD[0].product);
            this.orderItems.push(jsonData.INJECTION_PANEL_SUPER_NEGATIVE_THRESHOLD[4].product);
            this.orderItems.push(jsonData.INJECTION_PANEL_SUPER_NEGATIVE_THRESHOLD[8].product);
          }
          if (this.doorLeaf === 2) {
            jsonData.READY_PANEL_SUPER_NEGATIVE_THRESHOLD.map(item => {
              this.cuttingItems.push(item);
            });
            // Order List
            this.orderItems.push(jsonData.READY_PANEL_SUPER_NEGATIVE_THRESHOLD[0].product);
            this.orderItems.push(jsonData.READY_PANEL_SUPER_NEGATIVE_THRESHOLD[4].product);
            this.orderItems.push(jsonData.READY_PANEL_SUPER_NEGATIVE_THRESHOLD[8].product);
          }
        }
        if (this.thresholdType === 2) {
          if (this.doorLeaf === 1) {
            jsonData.SLIDING_INJECTION_PANEL_SUPER_NEGATIVE_NO_THRESHOLD.map(item => {
              this.cuttingItems.push(item);
            });
            // Order List
            this.orderItems.push(jsonData.SLIDING_INJECTION_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[0].product);
            this.orderItems.push(jsonData.SLIDING_INJECTION_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[4].product);
            this.orderItems.push(jsonData.SLIDING_INJECTION_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[8].product);
            jsonData.SLIDING_INJECTION_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[11].product.quantity = (this.width + 14) / 100;
            this.orderItems.push(jsonData.SLIDING_INJECTION_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[11].product);
          }
          if (this.doorLeaf === 2) {
            jsonData.READY_PANEL_SUPER_NEGATIVE_NO_THRESHOLD.map(item => {
              this.cuttingItems.push(item);
            });
            // Order List
            this.orderItems.push(jsonData.READY_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[0].product);
            this.orderItems.push(jsonData.READY_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[4].product);
            jsonData.READY_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[7].product.quantity = (this.width + 7) / 100;
            this.orderItems.push(jsonData.READY_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[7].product);
          }
        }
      }
      // Aksesuar Ekleme



      this.calculateHeater();
    }
    // Sürgülü Kapı"
    if (this.doorType === 2) {
      jsonData.SLIDING_DOOR_RAIL_ALUMINIUM_PROFILES.map(item => {
        if (item.product.code === 'NET-SKP-20') {
          item.value = 38 + this.width
          if(item.value >300) {
            item.value = (((this.width * 2) + 38) / 2) - this.width
            item.quantity = 2
          }
        }
        if (item.product.code === 'NET-SKP-22') {
          item.value = 38 + this.width
          if(item.value >300) {
            item.value = (((this.width * 2) + 38) / 2) - this.width
            item.quantity = 2
          }
        }
        if (item.product.code === 'NET-SKP-23') {
          item.value = 38 + this.width
          if(item.value >300) {
            item.value = (((this.width * 2) + 38) / 2) - this.width
            item.quantity = 2
          }
        }
        this.cuttingItems.push(item);
        console.log(this.cuttingItems);
      });
      jsonData.SLIDING_DOOR_RAIL_ALUMINIUM_PROFILES_2.map(item => {
        this.cuttingItems.push(item);
      });
      this.orderItems.push(jsonData.SLIDING_DOOR_RAIL_ALUMINIUM_PROFILES[0].product);
      this.orderItems.push(jsonData.SLIDING_DOOR_RAIL_ALUMINIUM_PROFILES[1].product);
      this.orderItems.push(jsonData.SLIDING_DOOR_RAIL_ALUMINIUM_PROFILES[2].product);
      this.orderItems.push(jsonData.SLIDING_DOOR_RAIL_ALUMINIUM_PROFILES[3].product);
      this.orderItems.push(jsonData.SLIDING_DOOR_RAIL_ALUMINIUM_PROFILES_2[0].product);
      if (this.slidingDoorsCase === 1 && this.thickness === 6) { // Plastik Kasa
        jsonData.SP027SERIES_NO_THRESHOLD.map(item => {
          this.cuttingItems.push(item);
        });

        this.orderItems.push(jsonData.SP027SERIES_NO_THRESHOLD[0].product);
        this.orderItems.push(jsonData.SP027SERIES_NO_THRESHOLD[3].product);
        this.orderItems.push(jsonData.SP027SERIES_NO_THRESHOLD[6].product);
        // this.orderItems.push(jsonData.SP027SERIES_NO_THRESHOLD[12].product);
      }
      if (this.slidingDoorsCase === 1 && this.thickness !== 6) { // Plastik Kasa
        jsonData.SP063SERIES_NO_THRESHOLD.map(item => {
          this.cuttingItems.push(item);
        });

        this.orderItems.push(jsonData.SP063SERIES_NO_THRESHOLD[0].product);
        if (this.slidingDoorsCase === 1 && this.thickness > 8) {
          this.orderItems.push(jsonData.SP063SERIES_NO_THRESHOLD[4].product);
        }
        if (this.slidingDoorsCase === 1 && this.thickness === 15) {
          this.orderItems.push(jsonData.SP063SERIES_NO_THRESHOLD[6].product);
        }
        this.orderItems.push(jsonData.SP063SERIES_NO_THRESHOLD[9].product);
        this.orderItems.push(jsonData.SP063SERIES_NO_THRESHOLD[12].product);
      }
      if (this.slidingDoorsCase === 2) { // Aluminyum Kasa
        jsonData.SP106SERIES_NO_THRESHOLD.map(item => {
          this.cuttingItems.push(item);
        });
        this.orderItems.push(jsonData.SP106SERIES_NO_THRESHOLD[0].product);
        this.orderItems.push(jsonData.SP106SERIES_NO_THRESHOLD[3].product);
        this.orderItems.push(jsonData.SP106SERIES_NO_THRESHOLD[6].product);

      }
      // Kanat

      if (this.temperature === 1 && this.doorLeaf === 1) {
        console.log(1);
        jsonData.SLIDING_INJECTION_PANEL_POSITIVE_NO_THRESHOLD.map(item => {
          this.cuttingItems.push(item);
        });
        this.orderItems.push(jsonData.SLIDING_INJECTION_PANEL_POSITIVE_NO_THRESHOLD[0].product);
        this.orderItems.push(jsonData.SLIDING_INJECTION_PANEL_POSITIVE_NO_THRESHOLD[4].product);
        this.orderItems.push(jsonData.SLIDING_INJECTION_PANEL_POSITIVE_NO_THRESHOLD[8].product);
        jsonData.SLIDING_INJECTION_PANEL_POSITIVE_NO_THRESHOLD[11].product.quantity = (this.width + 14) / 100;
        this.orderItems.push(jsonData.SLIDING_INJECTION_PANEL_POSITIVE_NO_THRESHOLD[11].product);
      }
      if (this.temperature === 2 && this.doorLeaf === 1) {
        jsonData.SLIDING_INJECTION_PANEL_NEGATIVE_NO_THRESHOLD.map(item => {
          this.cuttingItems.push(item);
        });
        this.orderItems.push(jsonData.SLIDING_INJECTION_PANEL_NEGATIVE_NO_THRESHOLD[0].product);
        this.orderItems.push(jsonData.SLIDING_INJECTION_PANEL_NEGATIVE_NO_THRESHOLD[4].product);
        this.orderItems.push(jsonData.SLIDING_INJECTION_PANEL_NEGATIVE_NO_THRESHOLD[8].product);
        jsonData.SLIDING_INJECTION_PANEL_NEGATIVE_NO_THRESHOLD[11].product.quantity = (this.width + 14) / 100;
        this.orderItems.push(jsonData.SLIDING_INJECTION_PANEL_NEGATIVE_NO_THRESHOLD[11].product);

      }
      if (this.temperature === 3 && this.doorLeaf === 1) {
        jsonData.SLIDING_INJECTION_PANEL_SUPER_NEGATIVE_NO_THRESHOLD.map(item => {
          this.cuttingItems.push(item);
        });
        this.orderItems.push(jsonData.SLIDING_INJECTION_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[0].product);
        this.orderItems.push(jsonData.SLIDING_INJECTION_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[4].product);
        this.orderItems.push(jsonData.SLIDING_INJECTION_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[8].product);
        jsonData.SLIDING_INJECTION_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[11].product.quantity = (this.width + 14) / 100;
        this.orderItems.push(jsonData.SLIDING_INJECTION_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[11].product);
      }

      if (this.temperature === 1 && this.doorLeaf === 2) {

        jsonData.SLIDING_READY_PANEL_POSITIVE_NO_THRESHOLD.map(item => {
          this.cuttingItems.push(item);
        });
        this.orderItems.push(jsonData.SLIDING_READY_PANEL_POSITIVE_NO_THRESHOLD[0].product);
        this.orderItems.push(jsonData.SLIDING_READY_PANEL_POSITIVE_NO_THRESHOLD[4].product);
        jsonData.SLIDING_READY_PANEL_POSITIVE_NO_THRESHOLD[7].product.quantity = (this.width + 14) / 100;
        this.orderItems.push(jsonData.SLIDING_READY_PANEL_POSITIVE_NO_THRESHOLD[7].product);
      }
      if (this.temperature === 2 && this.doorLeaf === 2) {
        jsonData.SLIDING_READY_PANEL_NEGATIVE_NO_THRESHOLD.map(item => {
          this.cuttingItems.push(item);
        });
        this.orderItems.push(jsonData.SLIDING_READY_PANEL_NEGATIVE_NO_THRESHOLD[0].product);
        this.orderItems.push(jsonData.SLIDING_READY_PANEL_NEGATIVE_NO_THRESHOLD[4].product);
        jsonData.SLIDING_READY_PANEL_NEGATIVE_NO_THRESHOLD[7].product.quantity = (this.width + 14) / 100;;
        this.orderItems.push(jsonData.SLIDING_READY_PANEL_NEGATIVE_NO_THRESHOLD[7].product);
      }
      if (this.temperature === 3 && this.doorLeaf === 2) {
      console.log('dwedwefwefwe');
        jsonData.SLIDING_READY_PANEL_SUPER_NEGATIVE_NO_THRESHOLD.map(item => {
          this.cuttingItems.push(item);
        });
        this.orderItems.push(jsonData.SLIDING_READY_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[0].product);
        this.orderItems.push(jsonData.SLIDING_READY_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[4].product);
        jsonData.SLIDING_READY_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[7].product.quantity = (this.width + 14) / 100;
        this.orderItems.push(jsonData.SLIDING_READY_PANEL_SUPER_NEGATIVE_NO_THRESHOLD[7].product);

      }
      const calculateHeat =
        {
          "id": 4,
          "code": "REZ-002",
          "title": "CUTTABLE_HEATER",
          "quantity": 1,
          "quantity2": ((((this.width + 14) + (this.height + 4))  * 2 )*0.01),
          "calculate": false,
          "image": "assets/img/REZ-02.png",
          "unit": "cm"
        }
      if (this.temperature !== 1){
        if (this.temperature === 3){
          calculateHeat.quantity2 = calculateHeat.quantity2 * 2;
        }

        this.orderItems.push(calculateHeat);
      }


      this.orderItems.push(jsonData.SLIDING_DOOR_ACCESSORIES[0]);


    }
    // Servis Kapısı
    if (this.doorType === 3) {
      this.orderItems = []
      // Kasa
      if (this.thickness === 6) { // Plastik Kasa
        jsonData.SP094SERIES_NO_THRESHOLD.map(item => {
          this.cuttingItems.push(item);
        });

        this.orderItems.push(jsonData.SP094SERIES_NO_THRESHOLD[0].product);
        this.orderItems.push(jsonData.SP094SERIES_NO_THRESHOLD[3].product);
        this.orderItems.push(jsonData.SP094SERIES_NO_THRESHOLD[6].product);

      } else {
        jsonData.SP0518SERIES_NO_THRESHOLD.map(item => {
          this.cuttingItems.push(item);
        });
        this.orderItems.push(jsonData.SP0518SERIES_NO_THRESHOLD[0].product);
        this.orderItems.push(jsonData.SP0518SERIES_NO_THRESHOLD[3].product);
        if (this.thickness === 15) {
          this.orderItems.push(jsonData.SP0518SERIES_NO_THRESHOLD[6].product);
        }
        this.orderItems.push(jsonData.SP0518SERIES_NO_THRESHOLD[9].product);
        this.orderItems.push(jsonData.SP0518SERIES_NO_THRESHOLD[12].product);
      }
      /// Kanat Oeodilini Sipariş Listesine Ekle
      this.orderItems.push(jsonData.SERVICE_DOOR_SINGLE_LEAF[0].product);
      this.orderItems.push(jsonData.SERVICE_DOOR_SINGLE_LEAF[4].product);

      if (this.doorLeafFlap === 1) {
        jsonData.SERVICE_DOOR_SINGLE_LEAF.map(item => {
          this.cuttingItems.push(item);
        });
        jsonData.SERVICE_DOOR_ACCESSORIES.map(item => {
          this.orderItems.push(item);
        });
      }
      if (this.doorLeafFlap === 2) {
        jsonData.SERVICE_DOOR_DOUBLE_LEAF.map(item => {
          this.cuttingItems.push(item);
        });
        jsonData.SERVICE_DOOR_ACCESSORIES_DOUBLE.map(item => {
          this.orderItems.push(item);
        });
      }

    }
    this.updateRowGroupMetaData()
    this.getOrderQuantity(this.orderItems)
    console.log(   this.orderItems)

  }


  calculateHeater() {
    if (this.temperature === 2) {
      const total = this.width + this.height;
      if (total <= 280) {
        this.orderItems.push(jsonData.HEATERS[0])
      }
      if (total > 280 && total <= 300) {
        this.orderItems.push(jsonData.HEATERS[1])
      }
      if (total > 300 && total <= 320) {
        this.orderItems.push(jsonData.HEATERS[2])
      }
      if (total > 320) {
        this.orderItems.push(jsonData.HEATERS[3])
      }
    }
    if (this.temperature === 3) {
      const selectItem = jsonData.HEATERS[4];
      const findIndex = this.orderItems.findIndex(obj => obj.code === 'REZ-002');
      this.orderItems.splice(findIndex, 1);
      // @ts-ignore
      selectItem.quantity = ((this.width + this.height) * 2 / 100) + 0.5
      this.orderItems.push(selectItem)
    }
  }

  selectDoorType(item) {
    this.doorType = item.id;
    this.doorTypeTitle = item.title;
    this.showControl()
  }

  selectHingedDoorsCase(item) {
    this.hingedDoorsCase = item.id;
    this.hingedDoorsCaseTitle = item.title;
    this.currentCase = item.items
    item.items.map(obj => {
      obj.cuttingByThreshold.map(cutObj => {
        const cutItem = {
          image: obj.image,
          code: obj.code,
          title: obj.title,
          cutImage: cutObj.image,
          cutName: cutObj.name,
          cutValue: cutObj.value,
          cutQuantity: cutObj.value,
        }
        this.currentCaseCutting.push(cutItem)
      })
    })
    this.showControl()
  }

  selectSlidingDoorsCase(item) {
    this.slidingDoorsCase = item.id;
    this.slidingDoorsCaseTitle = item.title;
    this.showControl()
  }

  selectDoorLeaf(item) {
    this.doorLeaf = item.id;
    this.doorLeafTitle = item.title;
    this.showControl()
  }

  selectTemperature(item) {
    this.temperature = item.id;
    this.temperatureTitle = item.title;
    this.showControl()
  }

  selectThresholdType(item) {
    this.thresholdType = item.id;
    this.thresholdTypeTitle = item.title;
    this.showControl()
  }

  selectWingType(item) {
    this.doorLeafFlap = item.id;
    this.doorLeafFlapTitle = item.title;
    this.showControl()
  }

  selectDimensions() {
    this.widthError = false;
    this.heightError = false;
    console.log(this.width, this.height)
    if (this.width !== undefined && this.height !== undefined && this.thickness !== undefined
      && this.width !== null && this.height !== null && this.thickness !== null) {
      this.isSelectedDimensions = true;
      this.showControl()
      this.runCalculate();
    }else {
      if (this.width === undefined || this.width === null) {
        this.widthError = true;
      }
      if (this.height === undefined || this.height === null) {
        this.heightError = true;
      }

    }

  }

  allHide() {
    this.isSelectedDoorType = false
    this.isSelectedWidth = false
    this.isSelectedHeight = false
    this.isSelectedThickness = false
    this.isSelectedTemperature = false
    this.isSelectedThreshold = false
    this.isSelectedDimensions = false
    this.isSelectedHingedDoorsCases = false
    this.isSelectedDoorLeaf = false
    this.isSelectedDoorLeafFlap = false
    this.isSelectedSlidingDoorsCases = false
    this.isSelectedQuantity = false
  }

  onSort() {
    this.updateRowGroupMetaData();
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};

    if (this.cuttingItems) {
      for (let i = 0; i < this.cuttingItems.length; i++) {
        let rowData = this.cuttingItems[i];
        let representativeName = rowData.product.name;
        if (i == 0) {
          this.rowGroupMetadata[representativeName] = {index: 0, size: 1};
        } else {
          let previousRowData = this.cuttingItems[i - 1];
          let previousRowGroup = previousRowData.product.name;
          if (representativeName === previousRowGroup)
            this.rowGroupMetadata[representativeName].size++;
          else
            this.rowGroupMetadata[representativeName] = {index: i, size: 1};
        }
      }
    }
  }

  downloadPDF() {
    const content = document.getElementById('printCuttingList') as HTMLElement; // PDF içeriği
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth * 0.9, pdfHeight);

      pdf.save(this.width + 'x' + this.height + 'x' + this.thickness + '-' + this.translate.instant(this.doorTypeTitle) + `.pdf`); // PDF'i indir
    });
  }

  downloadOrderPDF() {
    const content = document.getElementById('printOrderList') as HTMLElement; // PDF içeriği
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth * 0.9, pdfHeight);
      pdf.save(this.width + 'x' + this.height + 'x' + this.thickness + '-' + this.translate.instant(this.doorTypeTitle) + `.pdf`); // PDF'i indir
    });
  }


  async downloadAndSendOrderPDF() {
    // E-posta bilgilerini doldur
    const to = 'ramazandemirmail@gmail.com'; // Alıcı e-posta adresi
    const subject = 'Sipariş PDF\'si';
    const message = 'Lütfen ekli PDF dosyasını inceleyin.';

    this.pdfService.downloadAndSendOrderPDF(to, subject, message);
  }

  reload(): void {
    window.location.reload();
  }

  onEnter(event: KeyboardEvent) {
    if (event.key === "Enter") {
      (event.target as HTMLElement).blur(); // Enter tuşuna basıldığında blur işlemi
    }
  }

}
